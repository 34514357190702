import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { AuthQuery } from '../state/auth-state/auth.query';
import { HubConnectionBase } from './hub-connection-base';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
// import { SingleLearnCourseStore } from 'src/app/modules/learn/state/single-learn-course/single-learn-course.store';
// import { SingleMentorCourseStore } from 'src/app/modules/mentor/state/single-mentor-course/single-mentor-course.store';
// import { SingleSeminarStore } from 'src/app/modules/seminar/state/single-seminar/single-seminar.store';

@Injectable({
  providedIn: 'root',
})
export class EveryEngUserHubService extends HubConnectionBase {
  private hubConnectionSubject$: BehaviorSubject<signalR.HubConnection>;
  private connectionStatusSubject$ = new BehaviorSubject<boolean>(true);

  private LEARN_COURSE_UPDATED_CALLBACK: string = 'recordUpdated';
  private VIEW_COURSE_RECORD: string = 'SubscribeToRecord';
  private LEAVE_COURSE_RECORD: string = 'UnSubscribeToRecord';
  private MENTOR_COURSE_UPDATED_CALLBACK: string = 'recordUpdated';
  private VIEW_MENTOR_COURSE_RECORD: string = 'SubscribeToRecord';
  private LEAVE_MENTOR_COURSE_RECORD: string = 'UnSubscribeToRecord';
  private SEMINAR_UPDATED_CALLBACK: string = 'recordUpdated';
  private VIEW_SEMINAR_RECORD: string = 'SubscribeToRecord';
  private LEAVE_SEMINAR_RECORD: string = 'UnSubscribeToRecord';

  constructor(
    private authQuery: AuthQuery,
    // private singleMentorCourseStore: SingleMentorCourseStore,
    // private singleSeminarStore: SingleSeminarStore,
    // private singleCourseStore: SingleLearnCourseStore,
  ) {
    super();
    this.hubConnectionSubject$ = new BehaviorSubject<signalR.HubConnection>(
      this.hubConnection
    );
  }

  get hubConnectionEmitter(): BehaviorSubject<signalR.HubConnection> {
    return this.hubConnectionSubject$;
  }
  get connectionStatusEmitter(): BehaviorSubject<boolean> {
    return this.connectionStatusSubject$;
  }

  public connect() {
    if (
      this.hubConnection &&
      this.hubConnection.state == signalR.HubConnectionState.Connected
    ) {
      console.log('EveryEng User hub connection alive, returning existing one');
      this.hubConnectionSubject$.next(this.hubConnection);
    } else {
      this.buildAndStartConnection(
        apiOf.communication.chatHub,
        this.authQuery.getValue().__everyeng__eng_auth?.token
      );
    }
  }

  onConnectedCallback() {
    this.connectionStatusSubject$.next(true);
    this.hubConnectionSubject$.next(this.hubConnection);
  }

  public disConnect() {
    if (
      this.hubConnection &&
      this.hubConnection.state == signalR.HubConnectionState.Connected
    ) {
      this.hubConnection.stop()
    } 
  }

  
   _learnCourseUpdatedHandle() {
    if (this.hubConnection&&
      this.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.hubConnection.on(this.LEARN_COURSE_UPDATED_CALLBACK, (data) => {
        // this.singleCourseStore.update((state) => {
        //   if (state.singleCourse != null) {
        //     return {
        //       ...state,
        //       singleCourse: {
        //         ...state.singleCourse, ...data
        //       },
        //     };
        //   } else return state;
        // });
      });
    }
  }

  _viewCourseHandle(courseId: any, domain: any) {
    if (this.hubConnection&&
      this.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.hubConnection.send(this.VIEW_COURSE_RECORD, courseId, domain);
    }
  }

  _leaveCourseHandle(courseId: any, domain: any){
    if (this.hubConnection&&
      this.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.hubConnection.send(this.LEAVE_COURSE_RECORD, courseId, domain);
    }
  }

  
  _mentorCourseUpdatedHandle() {
    if (this.hubConnection) {
      this.hubConnection.on(this.MENTOR_COURSE_UPDATED_CALLBACK, (data) => {
        // this.singleMentorCourseStore.update((state) => {
        //   if (state.singleMentorCourse != null) {
        //     return {
        //       ...state,
        //       singleMentorCourse: {
        //         ...state.singleMentorCourse, ...data
        //       },
        //     };
        //   } else return state;
        // });
      });
    }
  }

  _viewMentorCourseHandle(courseId: any, domain: any) {
    if (this.hubConnection) {
      this.hubConnection.send(this.VIEW_MENTOR_COURSE_RECORD, courseId, domain);
    }
  }

  _leaveMentorCourseHandle(courseId: any, domain: any){
    if (this.hubConnection) {
      this.hubConnection.send(this.LEAVE_MENTOR_COURSE_RECORD, courseId, domain);
    }
  }

  _seminarUpdatedHandle() {
    if (this.hubConnection) {
      this.hubConnection.on(this.SEMINAR_UPDATED_CALLBACK, (data) => {
        // this.singleSeminarStore.update((state) => {
        //   if (state.singleSeminar != null) {
        //     return {
        //       ...state,
        //       singleSeminar: {
        //         ...state.singleSeminar, ...data
        //       },
        //     };
        //   } else return state;
        // });
      });
    }
  }

  _viewSeminarHandle(courseId: any, domain: any) {
    if (this.hubConnection) {
      this.hubConnection.send(this.VIEW_SEMINAR_RECORD, courseId, domain);
    }
  }

  _leaveSeminarHandle(courseId: any, domain: any){
    if (this.hubConnection) {
      this.hubConnection.send(this.LEAVE_SEMINAR_RECORD, courseId, domain);
    }
  }
  
  
}
