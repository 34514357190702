import * as signalR from '@microsoft/signalr';

export class HubConnectionBase {
  private RETYR_AFTER: number = 3000;
  private MAX_RETRY_COUNT: number = 10;
  private retryCount = 0;
  protected hubConnection: signalR.HubConnection;
  protected onConnectedCallback() {}

  protected buildAndStartConnection(hubUrl: any, bearerSecurityToken: any) {
    this.buildConnection(hubUrl, bearerSecurityToken);
    this.startConnection();
  }

  protected connect() {}

  private buildConnection = (hubUrl: string, bearerSecurityToken: string) => {
    var options = {
      transport: signalR.HttpTransportType.WebSockets,
      accessTokenFactory: () => {
        return bearerSecurityToken;
      },
      // skipNegotiation: true, 
    };
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(hubUrl, options)
      .build();
  };

  private startConnection = () => {
    this.hubConnection.state == signalR.HubConnectionState.Disconnected &&
      this.hubConnection
        .start()
        .then(() => {
          //TODO: Log to a remote logger
          //console.log("Hub connection Started...");
          this.retryCount = 0;
          this._registerOnClose();
          this.onConnectedCallback();
        })
        .catch((err) => {
          //TODO: Log to a remote logger
          //console.log("Error while starting chat hub: " + err);
          const nextRetryAfter = this.exponentialBackOffFun(++this.retryCount);
          if (this.retryCount <= this.MAX_RETRY_COUNT)
            setTimeout(() => {
              this.startConnection();
            }, nextRetryAfter);
        });
  };

  private _registerOnClose() {
    this.hubConnection &&
      this.hubConnection.onclose((error) => {
        this.connect();
      });
  }

  private exponentialBackOffFun = (x: any) => {
    return this.RETYR_AFTER * Math.pow(1.1, x);
  };
}
